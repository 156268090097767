import React from 'react';
import Table from 'rc-table';
import { formatDuration } from '../../../src/js/lib/duration';
import { formatNumber } from '../../../src/js/lib/number';
// import { addTippys } from '../../src/js/tooltips';
import BarLoader from 'react-spinners/BarLoader';
import Pagination from '../../Pagination';
import apiFetch from '../../../src/js/fetch';
import icExpand from '../../../images/icons/ic-chevron-down.svg';
import ProfilePicture from '../../Athletes/ProfilePicture';

class SegmentLeaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contestId: props.contest_id,
      segmentId: props.segment_id,
      userId: props.user_id,
      displayPace: false,
      contestSegmentEfforts: [], //props.contest_segment_efforts,
      loading: true,

      numPages: 1,
      currentPage: 1,
      data: [],
    };
  }

  componentDidMount() {
    // console.log({state: this.state});
    this.fetchEfforts(this.state.currentPage);
  }

  onPaginate = (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchEfforts(page);
    });
  };

  fetchHistory = async (expanded, record) => {
    // console.log({ expanded, record });
    if (
      !record.children ||
      !expanded ||
      !(record.children.length == 1 && record.children[0].loading)
    ) {
      // We only want to fetch the history once, and only when the row is expanded
      return;
    }

    const { segmentId, contestId, data } = this.state;
    const { contestantId } = record;

    try {
      const history = await apiFetch(
        `/api/challenges/segments/${segmentId}/leaderboard-history`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contest_id: contestId,
            contestant_id: contestantId,
          }),
        }
      );

      // console.log({ history });

      const efforts = [...data]; // copy the data
      const index = efforts.findIndex(
        (effort) => effort.contestantId === contestantId
      );

      // console.log({ efforts, index, contestantId });

      if (index >= 0) {
        if (history.efforts.length > 0) {
          efforts[index].children = history.efforts.map((effort, i) => ({
            speed: (
              <span className='mini-text text-text-grey-600'>
                {effort.speed} km/h
              </span>
            ),
            pace: (
              <span className='mini-text text-text-grey-600'>
                {formatDuration(effort.pace)} /km
              </span>
            ),
            watt: (
              <span className='mini-text text-text-grey-600'>
                {effort.avg_power_watts
                  ? effort.avg_power_watts.toFixed(0)
                  : '-'}
              </span>
            ),
            w_kg: (
              <span className='mini-text text-text-grey-600'>
                {effort.power_watt_per_kg && effort.power_watt_per_kg.toFixed
                  ? effort.power_watt_per_kg.toFixed(1)
                  : '-'}
              </span>
            ),
            time: (
              <span className='mini-text text-text-grey-600'>
                {formatDuration(effort.duration)}
              </span>
            ),
            className: 'bg-accent',
            history: (
              <span className='mini-text text-text-grey-600'>
                ({effort.date})
              </span>
            ),
          }));
        } else {
          efforts[index].children = [
            {
              loading: false,
              history: (
                <span className='mini-text text-text-grey-600'>
                  No history available
                </span>
              ),
            },
          ];
        }
      }

      this.setState({
        data: efforts,
      });
    } catch (e) {
      console.log({ e });
      const err = e.json ? await e.json() : null;
      console.log({ err });

      this.setState({
        loading: false,
        error:
          (err && err.error_message) ||
          'Something went wrong. Please try again later.',
      });
    }
  };

  fetchEfforts = async (page) => {
    this.setState({ loading: true });

    const { contestId, segmentId } = this.state;
    try {
      const data = await apiFetch(
        `/api/challenges/segments/${segmentId}/leaderboard`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contest_id: contestId,
            page: page,
          }),
        }
      );

      // console.log({ data });
      let displayPace = false;
      const efforts = data.efforts.map((effort, i) => {
        if (effort.pace) displayPace = true;

        return {
          position: effort.position + '.',
          challenger: (
            <div className='flex items-center'>
              <ProfilePicture {...effort.user} />
              <a className='underline' href={effort.user.url}>
                {effort.user.name}
              </a>
            </div>
          ),
          speed: `${effort.speed} km/h`,
          pace: effort.pace ? `${formatDuration(effort.pace)} /km` : '-',
          watt: effort.avg_power_watts
            ? effort.avg_power_watts.toFixed(0)
            : '-',
          w_kg:
            effort.power_watt_per_kg && effort.power_watt_per_kg.toFixed
              ? effort.power_watt_per_kg.toFixed(1)
              : '-',
          time: formatDuration(effort.duration),
          points: formatNumber(effort.points),
          className: 'bg-accent',
          userId: effort.user_id,
          contestantId: effort.contestant_id,
          history: (
            <span className='flex justify-between pr-2'>
              <span className='text-text-grey-600'>{effort.date}</span>
              <img src={icExpand} className='w-3' />
            </span>
          ),
          key: i + 'effort' + effort.id,

          children: [
            {
              loading: true,
              history: (
                <span className='mini-text text-text-grey-600'>Loading...</span>
              ),
            },
          ],
        };
      });

      this.setState({
        contestSegmentEfforts: data.efforts,
        displayPace,
        data: efforts,
        numPages: data.total_pages,
      });
    } catch (e) {
      console.log({ e });
      const err = e.json ? await e.json() : null;
      console.log({ err });

      this.setState({
        loading: false,
        error:
          (err && err.error_message) ||
          'Something went wrong. Please try again later.',
      });
    }
    this.setState({ loading: false });
  };

  render() {
    const { data, numPages, currentPage, loading, userId, displayPace } =
      this.state;

    const columns = [
      {
        title: '#',
        dataIndex: 'position',
        key: 'position',
        width: 100,
      },
      {
        title: 'Contestant',
        dataIndex: 'challenger',
        key: 'challenger',
        width: 'auto',
      },
      {
        title: 'Speed',
        dataIndex: 'speed',
        key: 'speed',
        width: 100,
      },
      displayPace && {
        title: 'Pace',
        dataIndex: 'pace',
        key: 'pace',
        width: 100,
      },
      {
        title: 'Watt',
        dataIndex: 'watt',
        key: 'watt',
        width: 100,
      },
      {
        title: 'W/kg',
        dataIndex: 'w_kg',
        key: 'w_kg',
        width: 100,
      },
      {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        width: 100,
      },
      {
        title: 'Points',
        dataIndex: 'points',
        key: 'points',
        width: 100,
      },
      {
        title: 'Date',
        dataIndex: 'history',
        key: 'history',
        width: 170,
      },
    ];

    return (
      <div className='w-full'>
        <h2 className='mini-label font-medium'>Segment leaderboard</h2>
        <div className='w-full overflow-x-auto'>
          <Table
            className='w-full mb-4'
            columns={columns}
            expandable={{
              expandRowByClick: true,
              onExpand: this.fetchHistory,
            }}
            emptyText={
              <p className='small-body-text text-text-grey'>
                {loading ? (
                  <BarLoader />
                ) : (
                  'No efforts registered. Be the first!'
                )}
              </p>
            }
            onRow={(row) => ({
              className: row.userId === userId ? 'current-user' : '',
            })}
            data={data}
          />
        </div>

        <Pagination
          numPages={numPages}
          currentPage={currentPage}
          onPaginate={this.onPaginate}
        />
      </div>
    );
  }
}
export default SegmentLeaderboard;
