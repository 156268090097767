import React, { useState } from 'react';
import SuperPopup from '../SuperPopup';

const Install = () => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };
  const onClick = () => {
    setOpen(true);
  };
  return (
    <>
      <button onClick={onClick} className='menu__item menu__item-appinstall'>
        Install the App
      </button>
      <SuperPopup title={'Install the App'} open={open} onClose={onClose}>
        <p>Test</p>
      </SuperPopup>
    </>
  );
};

export default Install;
